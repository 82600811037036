import React from 'react'
import logo from '../../../../assets/images/logo.png'
import NavProfile from '../../../../components/cv-builder/navigation/nav-profile'
import { Link, useNavigate } from 'react-router-dom'
import MobileMenu from './sidebar/mobile-menu'
import { Affix } from 'antd'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SwitchApp from './switch-app'

function Header ({ menuItems }) {
    const navigate = useNavigate()
    const userRole = useSelector((state) => state.loginReducer.authUser.role)

    return (
        <Affix offsetTop={0}>
            <div className={'flex items-center justify-between bg-blue-dark-50 border-b border-b-gray-200 px-2 md:px-[49px] h-[73px]'}>
                <div className="flex items-center gap-9">
                    <div onClick={() => {
                        navigate('/cv-builder/home')
                    }} className={'cursor-pointer'}>
                        <img className={'hidden md:block'} src={logo} alt={'AutoApply'} width={200} height={'auto'}/>
                        <img className={'block md:hidden'} src={logo} alt={'AutoApply'} width={200} height={'auto'}/>
                    </div>
                    <div className="flex gap-1">
                        {
                            userRole === 'user' && menuItems.map((menu) => (
                                <Link className={'text-base font-medium text-gray-700 py-2 px-3'} to={menu.link} key={menu.link}>{menu.title}</Link>
                            ))
                        }
                    </div>
                </div>
                <div className={'block md:hidden'}>
                    <MobileMenu/>
                </div>
                <div className={'flex items-center gap-x-2 md:gap-x-4'}>
                    {
                        userRole === 'user' &&
                        <SwitchApp/>
                    }
                    <NavProfile/>
                </div>
            </div>
        </Affix>
    )
}
Header.propTypes = {
    menuItems: PropTypes.array
}
export default Header
