import React, { useEffect, useState } from 'react'
import AuthLayout from './auth-layout'
import AuthBottomLink from './auth-bottom-link'
import { connect } from 'react-redux'
import { handleEmailVerification } from '../../actions/authenticate/Actions'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'

function VerifyEmail ({ verifyEmail }) {
  const [loading, setLoading] = useState(true)
  const { token } = useParams()

  useEffect(() => {
    verifyEmail(token).then(() => setLoading(false))
  }, [])

  return (
        <AuthLayout pageTitle={'Verify your email'}>
            <div className={'text-center'}>
                {
                    loading
                      ? <p className={'text-xl'}>Verification in progress. Please wait!</p>
                      : <div>
                            <p className={'text-2xl'}>Verification Complete</p>
                            <AuthBottomLink size={'sm'} text={''} link={'/'} linkText={'Login Here'}/>
                        </div>
                }
            </div>
        </AuthLayout>
  )
}

VerifyEmail.propTypes = {
  verifyEmail: PropTypes.func.isRequired
}
const mapDispatchToProps = (dispatch) => ({
  verifyEmail: (token) => dispatch(handleEmailVerification(token))
})

export default connect(null, mapDispatchToProps)(VerifyEmail)
