import React, { useState } from 'react'
import Resume from './resume'
import CoverLetter from './cover-letter'

function Apply () {
  const [active, setActive] = useState(0)

  const options = [
    {
      key: 'resume',
      label: 'For Resume',
      paragraph: 'Add job details and get your customized resume ready in seconds ',
      component: <Resume/>
    },
    {
      key: 'coverLetter',
      label: 'Cover Letter',
      paragraph: 'Add job details below and get your customized letter ready in seconds ',
      component: <CoverLetter/>
    }
  ]
  return (
        <div>
            <div className={'flex gap-5 text-sm font-medium'}>
                {
                    options.map((item, index) => (
                        <p onClick={() => setActive(index)} key={item.key}
                           className={`pb-[17px] border-b-2 cursor-pointer text-sm leading-5 font-medium mb-[10px]
                            ${index === active ? 'text-primary-700 border-primary-700' : 'text-gray-500'}`}>
                            {item.label}
                        </p>
                    ))
                }
            </div>
            <p className="text-gray-500 leading-6 mb-[14px]">{options[active]?.paragraph}</p>
            <div>
                {options[active].component}
            </div>
        </div>
  )
}

export default Apply
