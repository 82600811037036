import React, { useEffect, useState } from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { ModalRoute } from './modal-route'
import SignIn from '../../pages/auth/sign-in'
import Register from '../../pages/auth/register'
import ForgotPassword from '../../pages/auth/forgot-password'
import ResetPassword from '../../pages/auth/reset-password'
import Resume from '../../pages/resume'
import BuildResume from '../../pages/resume-builder'
import JobQueries from '../../pages/auto-apply/job-queries'
import JobsApplied from '../../pages/jobs-applied'
import AutoApplySessions from '../../pages/auto-apply-sessions'
import ResumeLayout from '../../pages/resume-builder/resume-layout'
import Questions from '../../pages/questions'
import Layout from '../../pages/v1-1/layout'
import FirstTimeUser from '../../pages/v1-1/first-time-user/first-time-user'
import JobQuerySuccess from '../../pages/v1-1/first-time-user/joq-query-sucess'
import NoSidebar from '../../pages/v1-1/layout/no-sidebar'
import Dashboard from '../../pages/dashboard'
import cvBuilder from './cv-builder'
import Iframe from '../../pages/iframe'
import { useDispatch, useSelector } from 'react-redux'
import { handleGetProfile } from '../../actions/authenticate/Actions'
import { hasNullOrEmptyValues, prefHasNullOrEmptyValues } from '../../utils'
import AppLock from '../app-lock'
import AppLockPreference from '../app-lock-preference'

export const ProtectedRoutes = () => {
    const location = useLocation()
    const background = location.state && location.state.background
    const [loading, setLoading] = useState(true)
    const authUserInfo = useSelector(state => state.loginReducer.authUserInfo)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(handleGetProfile()).then(() => {
            setLoading(false)
        })
    }, [])

    const data = {
        name: authUserInfo.name,
        resumeEmail: authUserInfo.resumeEmail,
        address: authUserInfo.address,
        mobileNumber: authUserInfo.mobileNumber,
        linkedin: authUserInfo.linkedin
    }

    return (
        <>
            <Routes location={ background || location }>
                <Route exact element={ <Iframe/> } path="/auto-apply"/>
                {
                    ((!loading && authUserInfo) && hasNullOrEmptyValues(data))
                        ? <Route path={'*'} element={ <AppLock/> }/>
                        : <>
                            {
                                ((!loading && authUserInfo) && prefHasNullOrEmptyValues(JSON.parse(authUserInfo?.otherQuestions).map(q => q.answer)))
                                    ? <Route path={'*'} element={ <AppLockPreference/> }/>
                                    : <>
                                        <Route exact element={ <Layout loading={ false }/> } path="/">
                                            <Route element={ <Dashboard/> } index/>
                                            <Route element={ <Dashboard/> } path={ 'home' }/>
                                            <Route element={ <FirstTimeUser/> } path={ '/first-time-user' }/>
                                            <Route element={ <JobQuerySuccess/> } path={ 'job-query-success' }/>
                                            <Route path="resume" element={ <Resume/> }/>
                                            <Route path="job-queries" index element={ <JobQueries/> }/>
                                            <Route path="auto-apply-sessions" element={ <AutoApplySessions/> }/>
                                            <Route path="questions" element={ <Questions/> }/>
                                        </Route>
                                        { cvBuilder }
                                        <Route path="/" element={ <NoSidebar/> }>
                                            <Route path="applied-jobs" element={ <JobsApplied/> }/>
                                        </Route>
                                        <Route exact element={ <ResumeLayout/> }>
                                            <Route path="build-resume" index element={ <BuildResume/> }/>
                                        </Route>
                                        <Route exact element={ <SignIn/> } path="/"/>
                                        <Route exact element={ <SignIn/> } path="/sign-in"/>
                                        <Route element={ <Register/> } path={ '/sign-up' }/>
                                        <Route element={ <ForgotPassword/> } path={ '/forgot-password' }/>
                                        <Route element={ <ForgotPassword/> } path={ '/forgot-password' }/>
                                        <Route element={ <ResetPassword/> } path={ '/resetpassword/:token' }/>
                                        <Route path={ '*' } element={ <>not found</> }/>
                                    </>
                            }
                        </>
                }

            </Routes>

            { background && (<><ModalRoute/> <Outlet/></>) }
        </>
    )
}
