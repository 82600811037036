import React from 'react'
import { Route } from 'react-router-dom'
import CvBuilderLayout from '../../pages/cv-builder/dashboard/layout'
import CvBuilderWithoutSidebarLayout from '../../pages/cv-builder/dashboard/layout/without-sidebar'
// import Dashboard from '../../pages/cv-builder/dashboard'
import Resumes from '../../pages/resumes'
import AiGenerator from '../../pages/resume/ai-generator'
import General from '../../pages/my-profile/general'
import Summary from '../../pages/my-profile/summary'
import Education from '../../pages/education'
import Experience from '../../pages/experience'
import Certification from '../../pages/certification'
import Awards from '../../pages/awards'
import Skills from '../../pages/skills'
import ChangePassword from '../../pages/my-profile/change-password'
import Subscription from '../../pages/subscription'
import CVBuilder from '../../pages/cv-builder'
// import ProfileLayoutLatest from '../../pages/cv-builder/dashboard/layout/profile-layout-latest'

export default [
    <Route key={'cv-builder'} path={'cv-builder'} element={<CvBuilderLayout/>}>
        {/* <Route path="home" element={<Dashboard/>}/> */}
        <Route path="ai-generator" element={<AiGenerator/>}/>
        <Route path="summary" element={<Summary/>}/>
        <Route path="education" element={<Education/>}/>
        <Route path="experience" element={<Experience/>}/>
        <Route path="certificates" element={<Certification/>}/>
        <Route path="awards-achievement" element={<Awards/>}/>
        <Route path="skills" element={<Skills/>}/>
        <Route path="subscription" element={<Subscription/>}/>
        <Route path="profile" element={<General/>}/>
        <Route path="settings" element={<ChangePassword/>}/>
    </Route>,
    <Route key={'cv-builder'} path={'cv-builder'} element={<CvBuilderWithoutSidebarLayout/>}>
        <Route exact path="home" element={<CVBuilder/>}/>
        <Route path="resume-template" element={<Resumes/>}/>
    </Route>
]
