import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import TlaAddNew from '../common/tla-add-new'
import { FiChevronDown } from 'react-icons/fi'

function TlaDropDown ({ icon, item, show, setShow, showToggler }) {
    const { pathname } = useLocation()
    const isCurrentPage = item?.children?.map((i) => i.link).includes(pathname)
    return (
        <div>
            {
                showToggler &&
                <p className={`w-full text-sm flex items-center justify-between px-4 py-2 cursor-pointer ${isCurrentPage || show ? 'bg-primary-600 text-white-base' : 'text-gray-700'} rounded-lg`}
                onClick={() => setShow(() => false)}>
                    <span className={'flex gap-2 items-center'}>
                        {icon} {item?.title}
                    </span>
                    <span><FiChevronDown style={{ transform: `${show ? 'rotate(180deg)' : 'rotate(0deg)'}` }}/></span>
                </p>
            }
            {show && (
                <div className="flex flex-col gap-1 pr-1" style={{ Transition: 'all 1s ease' }}>
                    {item?.children?.map(({ title, link, modal }) => (
                        modal
                            ? <TlaAddNew
                                className={`pl-11 hover:text-primary-700  rounded-md text-sm py-2 text-sm ${pathname === link ? 'text-primary-700' : 'text-gray-700'}`}
                                key={link} link={link}>
                                {title}
                            </TlaAddNew>
                            : <Link key={link} to={link} rel="opener"
                                    style={{ Transition: 'all 1s ease' }}
                                    className={`pl-11 hover:text-primary-700  rounded-md text-sm py-2 text-sm ${pathname === link ? 'text-primary-700' : 'text-gray-700'}`}>
                                {title}
                            </Link>
                    ))}
                </div>
            )}
        </div>
    )
}

TlaDropDown.defaultProps = {
    show: false,
    showToggler: true,
    options: []
}

TlaDropDown.propTypes = {
    show: PropTypes.bool,
    showToggler: PropTypes.bool,
    icon: PropTypes.any,
    item: PropTypes.object,
    setShow: PropTypes.func
}

export default TlaDropDown
