import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { handleGetStatistics } from '../../actions/dashboard/Actions'
import AiGenerator from '../resume/ai-generator'
function CVBuilder ({ getStatistics, statistics, userId }) {
    const [show, setShow] = useState(localStorage.getItem('setProfile') ?? 'true')
    const closeSetProfile = () => {
        localStorage.setItem('setProfile', 'false')
        setShow(false)
    }
    useEffect(() => {
      getStatistics(userId)
    }, [])
  const items = [
    {
      title: 'Resume Templates Created',
      total: statistics?.resumeTemplates ?? 0,
      other: '',
      app: 'cv-builder'
    },
    {
      title: 'Custom Letters Generated',
      total: statistics?.customCoverLetters?.used ?? 0,
      other: `${statistics?.customCoverLetters?.left ?? 0} Remaining`,
      app: 'cv-builder'
    },
    {
      title: 'Custom Resumes Generated',
      total: statistics?.customResumes?.used ?? 0,
      other: `${statistics?.customResumes?.left ?? 0} Left`,
      app: 'cv-builder'
    }
  ]
    return (
      <div className={'w-full rounded-lg py-[30px] px-[15px]'}>
        <div className="w-full flex justify-between items-start">
          {/* Left Side */}
          <div className="w-[885px]">
            <div className="flex flex-col gap-[49px] rounded-xl p-5 border boder-gray-200 bg-white-base">
              {
                show === 'true' &&
                <div className="flex gap-4 items-start">
                  <div className="flex-grow">
                    <h2 className="text-gray-700 text-3xl font-medium">Step 1: Review your info</h2>
                    <p className="text-gray-500 ">We have added information to your profile. Review it before getting started</p>
                  </div>
                  <div className="flex gap-3">
                    <Link to={'/cv-builder/summary'} className="py-2.5 px-4 rounded-lg border border-gray-300 bg-white-base shadow-sm text-gray-700 font-medium text-sm">See Profile</Link>
                    <MdClose onClick={closeSetProfile} className={'cursor-pointer text-primary-500'} size={20}/>
                  </div>
                </div>
              }
              <div className="flex gap-4 items-start">
                <div className="flex-grow">
                  <h2 className="text-gray-700 text-3xl font-medium">Step 2: Select Resume Template</h2>
                  <p className="text-gray-500 ">Choose resume of your choice to get started, customize it as you like</p>
                </div>
                <Link to={'/cv-builder/resume-template/form'} state={{ background: '/cv-builder/resume-template' }} className="py-2.5 px-4 rounded-lg border border-primary-600 bg-primary-600 shadow-sm text-white-base font-medium text-sm">Create Resume</Link>
              </div>
            </div>

            <div className="mt-20">
              <h2 className="font-medium text-lg leading-7 text-gray-900 mb-5">
                Instant Tailored AI Generator
              </h2>
            </div>
            <AiGenerator classes='!px-0 !pt-0' />
          </div>

          {/* Right Side */}
          <div className="rounded-[30px] bg-white-base py-[26px] px-9 flex flex-col gap-[46px] w-[333px]">
            {
              items.map(({ other, title, total }) => (
                <div key={title}>
                  <p className="text-sm font-medium leading-5 text-gray-500 !font-[Poppins,sans-serif] mb-2">
                    {title}
                  </p>
                  <div className="flex items-center gap-4">
                    <h3 className="font-semibold text-gray-900 text-3xl leading-[38px]">{total}</h3>
                    { other && <span className="bg-success-50 rounded-2xl font-medium text-sm text-success-700 leading-5 py-1 px-2.5">{other}</span> }
                  </div>
                </div>
              ))
            }
            <p className="text-center text-sm text-gray-900 leading-5 my-2">
              Package Reset Date: Sep 27, 2024
            </p>
            <Link className="py-2.5 px-4 rounded-[148px] border border-primary-600 bg-primary-600 text-white-base font-medium text-sm flex justify-center items-center">Change Package</Link>
          </div>
        </div>

      </div>
    )
}

CVBuilder.propTypes = {
  statistics: PropTypes.object.isRequired,
  userId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]),
  getStatistics: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  statistics: state.dashboardReducer.statistics,
  userId: state.loginReducer.authUser.id
})
const mapDispatchToProps = (dispatch) => ({
  getStatistics: (userId) => dispatch(handleGetStatistics(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CVBuilder)
