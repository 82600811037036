import React from 'react'
import { TlaModalLatest } from './pop-ups/tla-modal-latest'
import TlaAddNew from './common/tla-add-new'
import autoApply from '../utils/routes/auto-apply'
import Header from '../pages/dashboard/layout/header'
import { Outlet } from 'react-router-dom'
import { Affix } from 'antd'
import Sidebar from '../pages/v1-1/layout/sidebar'

function AppLockPreference () {
    return (
        <div className={ 'max-w-screen-2xl mx-auto' }>
            <Header/>
            <TlaModalLatest width={ 545 } allowClose={ false }>
                <div className={ 'bg-primary-25 -mt-6 py-2 px-5 rounded-lg font-medium text-center' }>
                    <h3 className="mb-2.5 text-[#000] text-[28px] font-medium">Set Personal Preferences</h3>
                    <p className={ 'mb-5 text-lg' }>
                        We also need your personal preferences to fill out job application forms.
                    </p>

                    <TlaAddNew pathname={ '/' } link={ autoApply.preferences }>
                        <div className={ 'w-full mx-auto bg-primary-600 px-[30px] py-2.5 rounded-[148px] text-white-base' }>
                            Add Now
                        </div>
                    </TlaAddNew>
                </div>
            </TlaModalLatest>
            <div className={ 'flex mt-[18px]' }>
                <div className={ 'grow' }>
                    <Outlet/>
                </div>
                <div className={ 'flex-none' }>
                    <Affix offsetTop={ 95 }>
                        <Sidebar/>
                    </Affix>
                </div>
            </div>
        </div>
    )
}

export default AppLockPreference
