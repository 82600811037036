import React from 'react'
import Apply from './apply'
import PropTypes from 'prop-types'

function AiGenerator ({ classes }) {
    return (
        <div className={`w-full rounded-lg py-[42px] px-[35px] ${classes}`}>
            <div>
                {/* <h3 className={'font-semibold text-xl text-blue-900 mb-5 font-lato'}>Apply 10X faster</h3> */}
                <Apply/>
                {/* <Overview/> */}
            </div>
        </div>
    )
}

AiGenerator.propTypes = {
    classes: PropTypes.string.isRequired
}

export default AiGenerator
